<template>
    <v-tooltip :disabled="tooltipDisabled" :small="small" bottom open-delay="200">
        <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                :left="left"
                :right="right"
                :color="color"
                :small="small"
                :large="large"
                >{{ icon }}</v-icon
            >
        </template>
        <span>{{ tooltip }}</span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'IconWithTooltip',
    watch: {
        show: function (value) {
            this.visible = value;
        },
    },
    props: {
        icon: {
            type: String,
            default: 'mdi-alert',
        },
        color: {
            type: String,
            default: null,
        },
        tooltip: {
            type: String,
            default: null,
        },
        small: {
            type: Boolean,
            default: false,
        },
        left: {
            type: Boolean,
            default: false,
        },
        right: {
            type: Boolean,
            default: false,
        },
        large: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        tooltipDisabled: function () {
            return !this.tooltip;
        },
    },
    created() {
        if (this.show) {
            this.visible = true;
        }
    },
};
</script>
