<template>
    <v-dialog
        v-model="visible"
        persistent
        :fullscreen="$vuetify.breakpoint.smAndDown || fullscreen"
        :transition="transition"
        :max-width="maxWidth"
        style="max-height: 100%;"
        scrollable
        @keydown.esc="closeDialog"
    >
        <v-card v-if="visible">
            <SpinnerOverlay absolute v-if="loading" />
            <v-row dense no-gutters class="shrink">
                <v-theme-provider dark>
                    <v-toolbar dark color="breeze_blue" flat>
                        <v-toolbar-title>{{ title }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="!closeHidden"
                            icon
                            dark
                            @click="closeDialog"
                            :disabled="loading || closeDisabled"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-theme-provider>
            </v-row>
            <v-card-text class="pa-6" :style="($vuetify.breakpoint.smAndDown || fullscreen) ? 'max-height: ' + maxHeightFullscreen + 'px;' : 'max-height: ' + maxHeight + 'px;'">
                <slot />
            </v-card-text>
            <v-divider v-if="$slots.actions || step" />
            <v-card-actions v-if="step && !hideStepButtons">
                <v-btn
                    v-if="step>1 && (!stepEnd || step<stepEnd)"
                    :disabled="stepBackDisabled"
                    text
                    @click="$emit('step-back')"
                >{{stepBackText}}</v-btn>
                <v-spacer v-if="step"></v-spacer>
                <v-btn
                    v-if="!stepEnd || step<stepEnd"
                    :disabled="stepNextDisabled"
                    text
                    @click="$emit('step-next')"
                >{{stepNextText}}</v-btn>
                <v-btn
                    v-if="step===stepEnd"
                    :disabled="stepEndDisabled"
                    text
                    @click="$emit('step-end')"
                >{{stepEndText}}</v-btn>
            </v-card-actions>
            <v-card-actions v-else-if="$slots.actions">
                <slot name="actions" />
            </v-card-actions>
            <SimpleDialog
                v-model="showConfirmClose"
                :title="closeConfirmationTitle"
                @click="closeDialog"
                persistent
            >
                {{ closeConfirmationMessage }}
            </SimpleDialog>
        </v-card>
    </v-dialog>
</template>

<script>
import SpinnerOverlay from '../../../components/pieces/Global/SpinnerOverlay';
import dialogMixin from '../../../mixins/dialogMixin';
import SimpleDialog from './SimpleDialog';
export default {
    name: 'ComplexDialog',
    components: { SimpleDialog, SpinnerOverlay },
    mixins: [dialogMixin],
    data: () => ({
        windowSize: {
            x: 0,
            y: 0
        },
        showConfirmClose: false
    }),
    methods: {
        resizeDialog() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },
        closeDialog() {
            if (this.closeConfirmation && !this.showConfirmClose && this.step!==this.stepEnd) {
                this.showConfirmClose = true;
            } else {
                if (this.showConfirmClose) this.showConfirmClose = false;
                this.visible = false;
            }
        },
        beforeUnload(event) {
            if (this.visible) {
                if(this.closeConfirmation) {
                  event.preventDefault();
                  event.returnValue = '';
                }
            } else {
                delete event['returnValue'];
            }
        },
    },
    computed: {
        transition: function () {
            return (this.$vuetify.breakpoint.smAndDown || this.fullscreen)
                ? 'dialog-bottom-transition'
                : 'scale-transition';
        },
        maxHeightFullscreen: function() {
            return this.windowSize.y > 150 ? (this.windowSize.y - 110) : 0;
        },
        maxHeight: function() {
            return this.windowSize.y > 480 ? (this.windowSize.y - 158) : 100;
        }
    },
    watch: {
        visible(value) {
            this.$emit(value ? 'show' : 'hide');
        }
    },
    created() {
        this.resizeDialog();
        window.addEventListener('resize', this.resizeDialog);
        window.addEventListener('beforeunload', this.beforeUnload);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeDialog);
        window.removeEventListener('beforeunload', this.beforeUnload);
        this.visible = false;
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        closeDisabled: {
            type: Boolean,
            default: false,
        },
        closeHidden: {
            type: Boolean,
            default: false,
        },
        closeConfirmation: {
            type: Boolean,
            default: false,
        },
        closeConfirmationMessage: {
            type: String,
            default: 'Any unsaved changes will be lost.',
        },
        closeConfirmationTitle: {
            type: String,
            default: 'Are you sure you want to cancel?',
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        fullscreen: {
            type: Boolean,
            default: false
        },
        maxWidth: {
            type: Number,
            default: 750
        },
        step: {
            type: Number,
            default: null
        },
        stepEnd: {
            type: Number,
            default: null
        },
        stepBackText: {
            type: String,
            default: 'Back',
        },
        stepBackDisabled: {
            type: Boolean,
            default: false,
        },
        stepNextText: {
            type: String,
            default: 'Next',
        },
        stepNextDisabled: {
            type: Boolean,
            default: false,
        },
        stepEndText: {
            type: String,
            default: 'Close',
        },
        stepEndDisabled: {
            type: Boolean,
            default: false,
        },
        hideStepButtons: {
          type: Boolean,
          default: false,
        },
    },
};
</script>