export default {
    data: () => ({
        visible: false,
    }),
    props: {
        title: {
            type: String,
            default: null,
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Boolean,
            default: false,
        },
        allowClose: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value(value) {
            if (this.visible!==value) this.visible = value;
        },
        visible(value) {
            if (this.value!==value) this.$emit('input', value);
        },
    },
    created() {
        if (this.visible!==this.value) this.visible = this.value;
    },
    beforeDestroy() {
        if (this.value) this.$emit('input', false);
    },
};
